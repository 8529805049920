import React from "react"
import { Container, Grid } from "semantic-ui-react"
import styles from "./contacts.module.scss"
import Socials from "../components/Socials"
import contactsConst from "../constants/contacts.json"
import { Helmet } from "react-helmet"

import itMessages from "../i18n/it.json"
import enMessages from "../i18n/en.json"

const messages = {
  it: itMessages,
  en: enMessages,
}

function contacts(props) {
  const { locale } = props.pageContext
  return (
    <>
      <Helmet>
        <title>
          {locale === "en" ? "Contacts - Amplitudo" : "Contatti - Amplitudo"}
        </title>
        <meta
          name="description"
          content={
            locale === "en"
              ? "Ready to work together? Give us a call at 3404121112 or 3270236297, we sure can listen. Alternatively, you can drop an email at hello@amplitudo.it"
              : "Ora non ti resta che contattarci e raccontarci il tuo progetto, siamo sempre pronti per nuove proposte. Chiama il 3404121112 o 3270236297 o scrivi a hello@amplitudo.it."
          }
        />
      </Helmet>
      <div className={styles.contactsPage}>
        <Container>
          <div className="centered-title-container">
            <div>
              <h1 className="centered-title">{messages[locale].contacts}</h1>
              <div className="centered-title-background"></div>
            </div>
          </div>
          <div className={styles.gridContainer}>
            <div className={styles.detailsContainer}>
              <h2>Email</h2>
              <span>{contactsConst.email}</span>
            </div>
            <div className={styles.detailsContainer}>
              <h2>{messages[locale].address}</h2>
              <span>
                {contactsConst.address1}
                <br />
                {contactsConst.address2}
              </span>
            </div>
            <div className={styles.detailsContainer}>
              <h2>{messages[locale].phone}</h2>
              <span>
                Daniele Prina
                <br />
                Executive Producer
                <br />
                {contactsConst.phone1}
                <br />
                <br />
                Fabio Di Santo
                <br />
                Creative Director
                <br />
                {contactsConst.phone2}
              </span>
            </div>
            <div className={styles.detailsContainer}>
              <h2>Socials</h2>
              <Socials />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default contacts
